import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Footer from '../footer'
import Modal from '../modal'

import '../../scss/main.scss'
// import CountDownOpenEnrollment from '../CountDownOpenEnrollment'

class Layout extends Component {
  componentDidMount = () => {
    this.props.checkUrlSrc(this.props.location.search)
    this.props.checkUrlCampaign(this.props.location.search)
    this.props.checkPrePopulatedData(this.props.location.search)
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.captive.campaign !== prevProps.captive.campaign) {
      if (window.dataLayer) {
        window.dataLayer.push({
          utm_campaign: this.props.captive.campaign,
        })
      }
    }
    if (this.props.captive.src !== prevProps.captive.src) {
      if (window.dataLayer) {
        window.dataLayer.push({
          src: this.props.captive.src,
        })
      }
    }
  }

  render() {
    const { children, phone, hideFooter } = this.props
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={(data) => (
          <div id="wrapper">
            <a className="accessibility" href="#main">
              Skip to Content
            </a>
            {/*this.props.showCountDown && <CountDownOpenEnrollment phone={phone} /> */}
            {children}
            {!hideFooter && <Footer phone={phone} />}
            <a className="accessibility" href="#wrapper">
              Back to top
            </a>
            <Modal type="terms" />
            <Modal type="privacy" />
          </div>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  phone: PropTypes.string,
  hideFooter: PropTypes.bool,
  showCountDown: PropTypes.bool,
}

Layout.defaultProps = {
  phone: ``,
  hideFooter: false,
  showCountDown: false,
}

export default Layout
